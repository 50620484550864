var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-app',[_c('v-system-bar',{staticStyle:{"white-space":"nowrap","text-overflow":"ellipsis","overflow":"hidden"},attrs:{"app":"","light":"","color":"amber lighten-2"}},[_vm._v(" "+_vm._s(_vm.$store.state.qr_event_data.show)+" "+_vm._s(_vm.$store.state.qr_event_data.date)+" "),_c('v-spacer'),(_vm.$store.state.mongerList.b)?_c('v-icon',[_vm._v("mdi-account")]):_c('v-icon',{staticClass:"red--text"},[_vm._v("mdi-account-off")]),_c('v-icon',{class:{'red--text':!_vm.$store.state.qr_event_data.b}},[_vm._v("mdi-ticket")])],1),_c('v-app-bar',{attrs:{"app":"","color":"amber lighten-1","light":"","dense":""}},[_c('v-app-bar-nav-icon',{attrs:{"color":"brown"},on:{"click":function($event){_vm.drawer = !_vm.drawer}}}),_c('div',{staticClass:"d-flex align-center"},[_vm._v(" ПроходЪ ")]),_c('v-spacer'),_vm._v(" ТТК ДК + ")],1),_c('v-navigation-drawer',{attrs:{"app":"","color":"lime lighten-4"},model:{value:(_vm.drawer),callback:function ($$v) {_vm.drawer=$$v},expression:"drawer"}},[_c('v-list',[_c('v-list-item',{staticClass:"pa-4 amber lighten-3"},[_c('v-img',{attrs:{"src":"https://zakaz.cloud/head.png","aspect-ratio":"1.7","height":"105","contain":""}})],1),_c('br'),_c('v-list-item-group',{attrs:{"active-class":"orange--text"},model:{value:(_vm.menu_selected),callback:function ($$v) {_vm.menu_selected=$$v},expression:"menu_selected"}},_vm._l((_vm.links_scanner),function(ref){
var icon = ref[0];
var text = ref[1];
var color = ref[2];
var r_link = ref[3];
var need_div = ref[4];
return _c('router-link',{key:icon,attrs:{"to":r_link},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var href = ref.href;
var route = ref.route;
var navigate = ref.navigate;
var isActive = ref.isActive;
var isExactActive = ref.isExactActive;
return [_c('v-list-item',{class:[isActive && 'router-link-active', isExactActive && 'router-link-exact-active'],on:{"click":navigate}},[_c('v-list-item-action',[_c('v-icon',{attrs:{"color":color}},[_vm._v(_vm._s(icon))])],1),_c('v-list-item-content',[_c('v-list-item-title',[_vm._v(_vm._s(text))])],1)],1)]}}],null,true)})}),1)],1)],1),_c('v-main',{staticClass:"amber lighten-5",staticStyle:{"word-wrap":"break-word"}},[_c('router-view')],1),_c('v-footer',{staticClass:"amber lighten-4",attrs:{"app":"","padless":""}},[_c('v-col',{staticClass:"text-center pa-0",attrs:{"cols":"12"}},[_c('v-chip',{staticClass:"ma-0",attrs:{"small":"","color":"red","text-color":"white"}},[_c('v-avatar',[_c('v-icon',{attrs:{"small":""}},[_vm._v("mdi-calendar-today")])],1)],1),_c('v-chip',{staticClass:"ma-2",attrs:{"small":"","color":"green","text-color":"white"}},[_c('v-avatar',{attrs:{"left":""}},[_c('v-icon',{attrs:{"small":""}},[_vm._v("mdi-barcode-scan")])],1),_vm._v(" "+_vm._s(_vm.$store.state.current_scan_info.txt_scan)+" ")],1),_c('v-chip',{staticClass:"ma-0",attrs:{"small":"","color":"blue","text-color":"white"}},[_c('v-avatar',[_c('v-icon',{attrs:{"small":""}},[_vm._v("mdi-chart-bar")])],1)],1)],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }